import IntlData from "./intlData"
import { getEstateId } from "../util/estates"
import { BTConfig } from "../recoil/types"

const { type, id } = getEstateId()

export default {
  intlData: IntlData,
  baseUrl: `api/v2/${type}/${id}`,
  remote: `api/v2/${type}/${id}/auth`,
  authFailureRedirect: "/admin",
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY || "",
  },
  resources: {
    locations: {
      enabled: true,
      views: ["within_bounds", "nearest_to", "complete"],
    },
  },

  map: {
    minSearchLocations: 0,
    settings: {
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoom: 5,
      center: {
        lat: 55,
        lng: -4,
      },
    },
  },
} satisfies Omit<
  BTConfig,
  | "user"
  | "estate"
  | "estate_group"
  | "analyse"
  | "metrics"
  | "download"
  | "aggregated_metrics"
  | "aggregated_metrics_download"
>
