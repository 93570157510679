import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { Fragment, ReactElement, useMemo } from "react"
import ReactDOM from "react-dom"
import { MenuButton } from "./buttons"

type AdminPanelMenuProps = {
  menuIsOpen: boolean
  menuItems: MenuButton[]
  buttonPosition: { bottom: number; right: number }
  onClose: () => void
}

export const AdminPanelMenu = ({
  menuIsOpen,
  menuItems,
  onClose,
  buttonPosition,
}: AdminPanelMenuProps): ReactElement | null => {
  const menuStyle = useMemo(() => {
    const arrowOffsetX = 11
    const arrowOffsetY = -20
    const menuTop = buttonPosition.bottom + arrowOffsetY
    const menuLeft = buttonPosition.right + arrowOffsetX
    return { top: `${menuTop}px`, left: `${menuLeft}px` }
  }, [buttonPosition])

  const { translate } = useI18n()

  if (!menuIsOpen) return null
  return ReactDOM.createPortal(
    <Fragment>
      <div className="admin-panel__menu-bg test-admin-menu-bg" onClick={onClose} />
      <div className="admin-panel__menu test-admin-menu" style={menuStyle}>
        {menuItems.map(({ onClick, name }) => (
          <button
            className="admin-panel__menu-item test-admin-menu-item"
            onClick={() => {
              onClick()
              onClose()
            }}
            key={name}
          >
            {translate(`navigation_menu.${name}`)}
          </button>
        ))}
      </div>
    </Fragment>,
    document.body
  )
}
