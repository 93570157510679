import { selectorFamily } from "@geome/recoil"
import moment from "moment"
import { inclusiveRange } from "../../util/dates"
import locationTypes from "../../values/locationTypes"
import { baseUrlAtom, userTypeAtom } from "../atoms"
import { AggregatedMetricsResponse } from "../types"

export const AggregatedMetricsSelectorFamily = selectorFamily<
  AggregatedMetricsResponse,
  {
    location_id: string
    start_date: string
    end_date: string
  }
>({
  key: "AggregatedMetricsSelectorFamily",
  get:
    ({ location_id, start_date, end_date }) =>
    async ({ get }) => {
      const baseUrl = get(baseUrlAtom)
      const userType = get(userTypeAtom)

      const url = `${baseUrl}/${
        location_id !== locationTypes.ESTATE ? `locations/${location_id}/` : ""
      }aggregate_metrics`

      const { start, end } = inclusiveRange({
        start: moment(start_date).valueOf(),
        end: moment(end_date).valueOf(),
      })

      const response = await fetch(
        `${url}?${new URLSearchParams({
          start_date: moment(start).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
          usage_group_type: userType !== "all" ? userType : "",
          format: "json",
        })}`
      )

      return (await response.json()) as AggregatedMetricsResponse
    },
})
