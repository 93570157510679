import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import React, { ReactElement, useState } from "react"
import { MetricCSVSelectorFamily } from "../../recoil/download/selectors"
import { DownloadIcon } from "../ui/icons/download"
import { useMetricsRouteMatch } from "../util/hooks/routes"
import { FileDownloader } from "../../util/fileDownloader"

export const MetricsDownloadButton = (): ReactElement => {
  const match = useMetricsRouteMatch()
  const [fileDownloader] = useState(() => new FileDownloader())
  const { translate } = useI18n()

  if (!match) throw new Error("MetricsDownloadButton not in metrics")
  const metricsDownload = useRecoilValue(MetricCSVSelectorFamily(match.params))

  return (
    <button
      onClick={() => {
        fileDownloader.download({
          name: `${metricsDownload.fileName}.csv`,
          content: metricsDownload.content,
          mimeType: "text/csv",
        })
      }}
      title={translate("download.as_csv") as string}
      className="download-button"
    >
      <DownloadIcon />
    </button>
  )
}
