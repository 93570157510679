import { User } from "../../recoil/types"
import backendLinks from "../../values/backendLinks"

export type MenuButton = { name: string; onClick: () => void }

export const getButtons = (user: User): MenuButton[] =>
  [
    user.admin && {
      name: "admin",
      onClick: () => (window.location.href = `${window.location.origin}${backendLinks.LOGIN}`),
    },
    user.multi_estate && {
      name: "switch_estates",
      onClick: () =>
        (window.location.href = `${window.location.origin}${backendLinks.ESTATE_PICKER}`),
    },
    {
      name: "reset_password",
      onClick: () =>
        (window.location.href = `${window.location.origin}${backendLinks.RESET_PASSWORD}`),
    },
    {
      name: "help",
      onClick: () =>
        window.open(
          "/data_admin/help",
          "_blank",
          "menubar=0,toolbar=0,scrolling=yes,scrollbars=yes,resizable=yes"
        ),
    },
    {
      name: "log_out",
      onClick: () => (window.location.href = `${window.location.origin}${backendLinks.LOGOUT}`),
    },
  ].filter(Boolean) as MenuButton[]
