import {
  analysePath,
  metricsPath,
  dateRangePath,
  resolutionPath,
  comparisonPath,
  aggMetricsPath,
} from "../../../values/pathParts"
import { useRouteMatch } from "react-router-dom"

export type RequiredRouteParams<params> = { params: params; url: string }
export type RouteParams<params> = RequiredRouteParams<params> | null

export const useAnalyseRouteMatch = (): RouteParams<{
  showing: string
  metric: string
  page: string
  start_date: string
  end_date: string
}> =>
  useRouteMatch(`/${analysePath}/${dateRangePath}/showing/:showing?/of/:metric?/page/:page(\\d+)`)

export const useMetricsRouteMatch = (): RouteParams<{
  resolution: "daily" | "weekly" | "monthly"
  location_id: string
  start_date: string
  end_date: string
}> => useRouteMatch(`/${metricsPath}/${dateRangePath}/${resolutionPath}`)

export const useAggMetricsRouteMatch = (): RouteParams<{
  location_id: string
  start_date: string
  end_date: string
}> => useRouteMatch(`/${aggMetricsPath}/${dateRangePath}`)

export const useComparisonRouteMatch = (): RouteParams<{
  resolution: "daily" | "weekly" | "monthly"
  location_ids?: string[]
  start_date: string
  end_date: string
}> => {
  const match = useRouteMatch(`/${comparisonPath}/${dateRangePath}/${resolutionPath}`)
  if (!match) return match
  const { params } = match
  return {
    ...match,
    params: {
      ...params,
      location_ids: params.location_ids ? params.location_ids.split("/") : [],
    },
  }
}

export const useMetricsOrComparisonRouteMatch = (): RouteParams<{
  mode: "metrics" | "comparison"
  resolution?: "daily" | "weekly" | "monthly"
  location_ids: string[]
  start_date: string
  end_date: string
}> => useRouteMatch(`/:mode(metrics|comparison)/:location_ids*/${dateRangePath}/${resolutionPath}?`)

export const useAnyDateModeRouteMatch = (): RouteParams<{
  mode: "metrics" | "comparison" | "agg_metrics"
  resolution?: "daily" | "weekly" | "monthly"
  location_ids: string[]
  start_date: string
  end_date: string
}> =>
  useRouteMatch(
    `/:mode(metrics|agg_metrics|comparison)/:location_ids*/${dateRangePath}/${resolutionPath}?`
  )

export const useDateRangeRouteMatch = (): RouteParams<{
  start_date: string
  end_date: string
}> => useRouteMatch(`*/${dateRangePath}/*`)
