import React, { ReactElement, ReactNode } from "react"
import { BTLogo } from "../../../assets/images"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"

type AppLayoutProps = {
  renderContent: () => ReactNode
  renderHeader: () => ReactNode
  renderSidebar: () => ReactNode
}

export const AppLayout = ({
  renderContent,
  renderHeader,
  renderSidebar,
}: AppLayoutProps): ReactElement => {
  const { translate } = useI18n()
  return (
    <div className="app">
      <div className="app__header">
        <div className="app__header-bt">
          <div className="app__header-inner u-flex-row u-flex-justify u-flex-center group-padding-x">
            <img className="app__header-bt-logo" src={BTLogo} width="200" height="95" />
            <div>{translate("portal_name")}</div>
          </div>
        </div>
        <div className="app__header-inner">{renderHeader()}</div>
      </div>
      <div className="app__content-container">
        <div className="app__sidebar-container">
          <div className="app__sidebar">{renderSidebar()}</div>
        </div>
        <div className="app__content">{renderContent()}</div>
      </div>
    </div>
  )
}
