import { atom } from "@geome/recoil"
import { User, Estate, BTLocation, UserType, BTConfig, Resolution } from "./types"

export const userAtom = atom<User | null>({ key: "userAtom", default: null })
export const estateAtom = atom<Estate | null>({ key: "estateAtom", default: null })

export const selectedLocationIdsAtom = atom<BTLocation["id"][]>({
  key: "selectedLocationIdsAtom",
  default: [],
})

export const baseUrlAtom = atom<string>({ key: "baseUrlAtom", default: "" })

export const userTypeAtom = atom<UserType>({ key: "userTypeAtom", default: "all" })

export const startDateAtom = atom<string | null>({ key: "startDateAtom", default: null })
export const endDateAtom = atom<string | null>({ key: "endDateAtom", default: null })
export const resolutionAtom = atom<Resolution | null>({ key: "resolutionAtom", default: null })

export const baseConfigAtom = atom<BTConfig | null>({
  key: "baseConfigAtom",
  default: null,
})
