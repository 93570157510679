import React, { ReactElement } from "react"
import moment, { Moment } from "moment"
import { dateRangeIsSame } from "../../util/dates"
import { ButtonPrimary } from "../ui/buttonPrimary"
import { ButtonToggle } from "../ui/buttonToggle"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"

type ResolutionUnit = "days" | "weeks" | "years"

type RangeButtonProps = {
  count: number
  unit: ResolutionUnit
  textToken: string
  interimStartDate: Moment
  interimEndDate: Moment
  onSetRange: (count: number, unit: ResolutionUnit) => void
}

const RangeButton = ({
  count,
  unit,
  textToken,
  interimStartDate,
  interimEndDate,
  onSetRange,
}: RangeButtonProps): ReactElement => {
  const { translate } = useI18n()

  const isSame = dateRangeIsSame(
    {
      startDate: interimStartDate,
      endDate: interimEndDate,
    },
    {
      startDate: moment().subtract(count, unit),
      endDate: moment(),
    }
  )
  return (
    <ButtonToggle
      className={`u-mr1 test-datepicker-range-${count}-${unit}`}
      toggled={isSame}
      small={true}
      onClick={() => onSetRange(count, unit)}
    >
      {translate(`date_picker.range.${textToken}`) as string}
    </ButtonToggle>
  )
}

type AdditionalButtonsProps = {
  onApply: (start: Moment, end: Moment) => void
} & Pick<RangeButtonProps, "onSetRange" | "interimStartDate" | "interimEndDate">

export const AdditionalButtons = (props: AdditionalButtonsProps): ReactElement => {
  const { translate } = useI18n()
  const { onApply, ...throughProps } = props
  const { interimStartDate, interimEndDate } = throughProps

  return (
    <div className="group-padding-x u-pb1 u-flex-row u-flex-justify">
      <div className="u-flex-row">
        <RangeButton count={7} unit="days" textToken="last_week" {...throughProps} />
        <RangeButton count={4} unit="weeks" textToken="last_four_weeks" {...throughProps} />
        <RangeButton count={1} unit="years" textToken="last_year" {...throughProps} />
        <RangeButton count={2} unit="years" textToken="last_two_years" {...throughProps} />
      </div>
      <div>
        <ButtonPrimary
          onClick={() => onApply(interimStartDate, interimEndDate)}
          className="date-picker__apply-button test-datepicker-apply"
        >
          {translate("date_picker.apply") as string}
        </ButtonPrimary>
      </div>
    </div>
  )
}
