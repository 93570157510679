import { Resolution } from "../../recoil/types"
import { Resolutions } from "../../values/resolutions"

const THREE_MONTHS = 93
const ONE_LEAP_YEAR = 366
const THREE_WEEKS = 21
const LESS_THAN_THREE_MONTHS = 92

export const getDaysBetween = ({
  start_date,
  end_date,
}: {
  start_date: string
  end_date: string
}): number => {
  const startDate = new Date(start_date)
  const endDate = new Date(end_date)
  return (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
}

export const isResolutionEnabled = (resolution: Resolution, days: number): boolean => {
  if (resolution === Resolutions.daily) {
    if (days > THREE_MONTHS) return false
  } else if (resolution === Resolutions.weekly) {
    if (days > ONE_LEAP_YEAR) return false
    if (days < THREE_WEEKS) return false
  } else if (resolution === Resolutions.monthly) {
    if (days < LESS_THAN_THREE_MONTHS) return false
  }
  return true
}
