import React, { ReactElement, ReactNode } from "react"

type ButtonPrimaryProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  full?: boolean
  useIcon?: boolean
  onClick: () => void
  small?: boolean
}

const baseClassName = "button-primary"

export const ButtonPrimary = ({
  children,
  className: additionalClassName,
  disabled,
  full,
  useIcon,
  onClick,
  small,
}: ButtonPrimaryProps): ReactElement => {
  const className = [
    baseClassName,
    full && `${baseClassName}--full`,
    small && `${baseClassName}--small`,
    useIcon && `${baseClassName}--icon`,
    additionalClassName,
    "clean-button",
  ]
    .filter(Boolean)
    .join(" ")

  return (
    <button className={className} onClick={onClick} disabled={disabled || false}>
      {children}
    </button>
  )
}
