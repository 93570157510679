import React, { ReactElement, useEffect } from "react"
import { useDateRangeRouteMatch } from "../util/hooks/routes"
import { DateRangePicker } from "react-dates"
import moment from "moment"
import { AdditionalButtons } from "./additionalButtons"
import { Redirect } from "react-router-dom"
import _ from "lodash"
import { useDatePickerReducer } from "./useDatePickerReducer"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useSetRecoilState } from "@geome/recoil"
import { endDateAtom, startDateAtom } from "../../recoil/atoms"

const DATE_FORMAT = "YYYY-MM-DD"

export const DatePicker = (): ReactElement => {
  const { translate } = useI18n()
  const match = useDateRangeRouteMatch()

  const setStartDateAtom = useSetRecoilState(startDateAtom)
  const setEndDateAtom = useSetRecoilState(endDateAtom)
  useEffect(() => {
    if (match?.params.start_date && match.params.end_date) {
      setStartDateAtom(match.params.start_date)
      setEndDateAtom(match.params.end_date)
    }
  }, [match?.params.start_date, match?.params.end_date, setStartDateAtom, setEndDateAtom])

  if (!match) throw Error("Datepicker rendered without dates")
  const { params } = match
  const [state, dispatch] = useDatePickerReducer(params)
  const urlMatchesDates = _.isEqual(
    { start_date: params.start_date, end_date: params.end_date },
    state.dates
  )

  useEffect(() => {
    if (state.applied && urlMatchesDates) dispatch({ type: "has_redirected" })
  }, [urlMatchesDates])

  if (state.applied && !urlMatchesDates) {
    return (
      <Redirect
        to={`${params[0]}/${state.dates.start_date}/${state.dates.end_date}/${params[1]}`}
        push
      />
    )
  }

  return (
    <DateRangePicker
      startDate={moment(state.dates.start_date)}
      endDate={moment(state.dates.end_date)}
      startDateId="start_date_id"
      endDateId="end_date_id"
      onDatesChange={({ startDate, endDate }) =>
        dispatch({
          type: "change_dates",
          payload: {
            start_date: startDate?.format(DATE_FORMAT) || params.start_date,
            end_date: endDate?.format(DATE_FORMAT) || params.end_date,
          },
        })
      }
      focusedInput={state.focusedInput}
      onFocusChange={(inputName) => dispatch({ type: "focus_input", payload: inputName })}
      isOutsideRange={(date) => date.isAfter(Date.now())}
      displayFormat="D/M/YYYY"
      readOnly
      hideKeyboardShortcutsPanel
      customInputIcon={<div>{translate("date_picker.showing")}</div>}
      renderCalendarInfo={() => (
        <AdditionalButtons
          onApply={() => dispatch({ type: "apply" })}
          onSetRange={(count, unit) =>
            dispatch({
              type: "change_dates",
              payload: {
                start_date: moment().subtract(count, unit).format(DATE_FORMAT),
                end_date: moment().format(DATE_FORMAT),
              },
            })
          }
          interimStartDate={moment(state.dates.start_date)}
          interimEndDate={moment(state.dates.end_date)}
        />
      )}
      keepOpenOnDateSelect
      minimumNights={0}
    />
  )
}
