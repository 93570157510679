import React, { ReactElement, ReactNode } from "react"

type ButtonToggleProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  full?: boolean
  small?: boolean
  onClick: () => void
  theme?: string
  toggled?: boolean
}

const baseClassName = "button-toggle"

export const ButtonToggle = ({
  children,
  className: additionalClassName,
  disabled,
  full,
  small,
  onClick,
  theme,
  toggled,
}: ButtonToggleProps): ReactElement => {
  const className = [
    baseClassName,
    full && `${baseClassName}--full`,
    toggled && "is-toggled",
    theme && `${baseClassName}--${theme}`,
    small && `${baseClassName}--small`,
    additionalClassName,
    "clean-button",
  ]
    .filter(Boolean)
    .join(" ")
  return (
    <button className={className} onClick={onClick} disabled={disabled || false}>
      {children}
    </button>
  )
}
