import { selectorFamily } from "@geome/recoil"
import { userTypeAtom } from "../atoms"
import {
  aggMetricsDownloadConfigSelector,
  downloadConfigSelector,
  LocationSelectorFamily,
  MetricsSelectorFamily,
} from "../selectors"
import PapaParse from "papaparse"
import { MetricName, Resolution } from "../types"
import { AggregatedMetricsSelectorFamily } from "../aggregated/selectors"

type MetricRow = { date: string } & { [K in MetricName]?: number }

export const PerDateMetricsSelectorFamily = selectorFamily<
  MetricRow[],
  {
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
  }
>({
  key: "PerDateMetricsSelectorFamily",
  get:
    ({ location_id, start_date, end_date, resolution }) =>
    async ({ get }) => {
      const metricsData = get(
        MetricsSelectorFamily({ location_id, start_date, end_date, resolution })
      ).data

      const perDate = Object.entries(metricsData)
        .flatMap(([metric, values]) =>
          values.map(({ start_date, value }) => ({ metric, value, date: start_date }))
        )
        .reduce(
          (acc, { metric, value, date }) => ({
            ...acc,
            [date]: { ...(acc[date] || {}), [metric]: value },
          }),
          {} as Record<string, undefined | Omit<MetricRow, "date">>
        )

      const rows = Object.entries(perDate).map(([date, values]) => ({
        date,
        ...(values as Omit<MetricRow, "date">),
      }))
      return rows
    },
})

export const MetricCSVSelectorFamily = selectorFamily<
  { content: string; fileName: string },
  {
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
  }
>({
  key: "MetricCSVSelectorFamily",
  get:
    ({ location_id, start_date, end_date, resolution }) =>
    async ({ get }) => {
      if (!location_id) return { fileName: "None", content: "" }

      const location = get(LocationSelectorFamily(location_id))

      const downloadConfig = get(downloadConfigSelector)
      const userType = get(userTypeAtom)

      const content = PapaParse.unparse({
        fields:
          location_id === "estate"
            ? downloadConfig.columns_estate
            : downloadConfig.columns_single_location,
        data: get(PerDateMetricsSelectorFamily({ location_id, start_date, end_date, resolution })),
      })
      const fileName = [
        location.name,
        start_date,
        end_date,
        resolution,
        userType !== "all" && userType,
      ]
        .filter(Boolean)
        .join("-")

      return { content, fileName }
    },
})

export const AggMetricCSVSelectorFamily = selectorFamily<
  { content: string; fileName: string },
  {
    location_id: string
    start_date: string
    end_date: string
    name: string
  }
>({
  key: "AggMetricCSVSelectorFamily",
  get:
    ({ location_id, start_date, end_date, name }) =>
    async ({ get }) => {
      if (!location_id) return { fileName: "None", content: "" }

      const location = get(LocationSelectorFamily(location_id))

      const downloadConfig = get(aggMetricsDownloadConfigSelector)
      const columns = downloadConfig[name].columns
      const userType = get(userTypeAtom)

      const selectorParams = { location_id, start_date, end_date }
      const data = get(AggregatedMetricsSelectorFamily(selectorParams)).data[name]

      if (!data) throw new Error(`${name} not in AggregatedMetricsSelectorFamily data`)

      const dataObj = Object.fromEntries(data.map(({ key, value }) => [key, value ?? ""]))
      const orderedData = columns.map((col) => dataObj[col])

      const content = PapaParse.unparse({
        fields: columns,
        data: orderedData,
      })
      const fileName = [
        location.name.split(" ").filter(Boolean).join("-"),
        start_date,
        end_date,
        userType !== "all" && userType,
      ]
        .filter(Boolean)
        .join("-")

      return { content, fileName }
    },
})

export const ComparisonCSVSelectorFamily = selectorFamily<
  { content: string; fileName: string },
  {
    location_ids?: string[]
    start_date: string
    end_date: string
    resolution: Resolution
  }
>({
  key: "ComparisonCSVSelectorFamily",
  get:
    ({ location_ids, start_date, end_date, resolution }) =>
    async ({ get }) => {
      if (!location_ids) return { fileName: "None", content: "" }

      const downloadConfig = get(downloadConfigSelector)
      const locations = await Promise.all(location_ids.map((id) => get(LocationSelectorFamily(id))))

      const rows = locations
        .map(({ id, name, status }) => {
          const locationRows = get(
            PerDateMetricsSelectorFamily({ location_id: id, start_date, end_date, resolution })
          )

          return locationRows.map((l) => ({
            ...l,
            location_id: id,
            location_name: name,
            location_status: status,
          }))
        })
        .flat()

      const sortedRows = [...rows].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )

      const userType = get(userTypeAtom)
      const content = PapaParse.unparse({
        fields: downloadConfig.columns_comparison,
        data: sortedRows,
      })

      const fileName = [
        ...location_ids,
        start_date,
        end_date,
        resolution,
        userType !== "all" && userType,
      ]
        .filter(Boolean)
        .join("-")

      return { content, fileName }
    },
})
