import React, { Fragment, ReactElement, useState } from "react"
import { AvatarPlaceholder } from "../../assets/images"
import { GetPosition } from "../util/getPosition"
import { AdminPanelMenu } from "./menu"
import DownArrow from "../../assets/downArrow"
import { ServiceTypeIcon } from "./serviceTypeIcon"
import { getButtons } from "./buttons"
import { useRecoilValue } from "@geome/recoil"
import { estateAtom, userAtom } from "../../recoil/atoms"

export const AdminPanel = (): ReactElement | null => {
  const [menuOpen, setMenuOpen] = useState(false)
  const user = useRecoilValue(userAtom)
  const estate = useRecoilValue(estateAtom)
  if (!estate || !user) return null

  const logoImage = estate.logo_url || AvatarPlaceholder
  const menuItems = getButtons(user)

  return (
    <div className="admin-panel test-admin-panel">
      <div className="admin-panel__avatar test-admin-avatar">
        <div className="admin-panel__avatar-img" style={{ backgroundImage: `url(${logoImage})` }} />
      </div>
      <div className="admin-panel__info">
        <div className="admin-panel__info-name test-admin-name">{estate.name}</div>
        <div className="admin-panel__info-service-type-icons">
          {estate.service_types.map((type) => (
            <ServiceTypeIcon
              key={type}
              className="admin-panel__info-service-type-icon"
              serviceType={type}
            />
          ))}
        </div>
        <div className="admin-panel__info-display-name test-admin-display-name">
          {user.display_name}
        </div>
      </div>
      <div className="admin-panel__menu-button-container">
        <GetPosition>
          {(buttonPosition) => (
            <Fragment>
              <button
                className="admin-panel__menu-button test-admin-toggle-menu"
                onClick={() => setMenuOpen(true)}
              >
                <DownArrow />
              </button>
              <AdminPanelMenu
                buttonPosition={buttonPosition}
                menuItems={menuItems}
                menuIsOpen={menuOpen}
                onClose={() => setMenuOpen(false)}
              />
            </Fragment>
          )}
        </GetPosition>
      </div>
    </div>
  )
}
