import React, { ReactElement, Suspense } from "react"
import { DownloadIcon } from "../ui/icons/download"
import { MetricsDownloadButton } from "./metrics"
import { ComparisonDownloadButton } from "./comparison"
import { Route, Switch } from "react-router"
import {
  analysePath,
  comparisonPath,
  dateRangePath,
  metricsPath,
  resolutionPath,
} from "../../values/pathParts"
import { AnalysisDownloadButton } from "./analyse"

export const DownloadButton = (): ReactElement | null => (
  <Suspense
    fallback={
      <button className="download-button" disabled>
        <DownloadIcon />
      </button>
    }
  >
    <Switch>
      <Route path={`/${metricsPath}/${dateRangePath}/${resolutionPath}`}>
        <MetricsDownloadButton />
      </Route>
      <Route path={`/${comparisonPath}/${dateRangePath}/${resolutionPath}`}>
        <ComparisonDownloadButton />
      </Route>
      <Route
        path={`/${analysePath}/${dateRangePath}/showing/:showing?/of/:metric?/page/:page(\\d+)`}
      >
        <AnalysisDownloadButton />
      </Route>
    </Switch>
  </Suspense>
)
