import React, { ReactElement } from "react"

export const ListIcon = (): ReactElement => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="25" stroke="#5514B4" strokeWidth="2" />
    <path
      d="M10 27.6667H13.5556V24.1111H10V27.6667ZM10 34.7778H13.5556V31.2222H10V34.7778ZM10 20.5556H13.5556V17H10V20.5556ZM17.1111 27.6667H42V24.1111H17.1111V27.6667ZM17.1111 34.7778H42V31.2222H17.1111V34.7778ZM17.1111 17V20.5556H42V17H17.1111Z"
      fill="#5514B4"
    />
  </svg>
)
