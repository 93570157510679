export const FOR_ESTATE = "estates"
export const FOR_ESTATE_GROUP = "estate_groups"

export const getEstateId = (
  url = window.document.location.href
): { id: string | null; type: "estates" | "estate_groups" } => {
  const { searchParams } = new window.URL(url)
  const estateId = searchParams.get("estate_id")
  const estateGroupId = searchParams.get("estate_group_id")

  if (estateGroupId) {
    return { id: estateGroupId, type: FOR_ESTATE_GROUP }
  } else if (estateId) {
    return { id: estateId, type: FOR_ESTATE }
  }
  return { id: null, type: FOR_ESTATE }
}
