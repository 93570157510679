import React, { ReactElement, useEffect } from "react"
import { Resolutions } from "../../values/resolutions"
import { NavLink, Redirect } from "react-router-dom"
import { useMetricsOrComparisonRouteMatch } from "../util/hooks/routes"
import { isResolutionEnabled, getDaysBetween } from "./util"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { Resolution } from "../../recoil/types"
import { useSetRecoilState } from "@geome/recoil"
import { resolutionAtom } from "../../recoil/atoms"

export const ResolutionPicker = (): ReactElement | null => {
  const { translate } = useI18n()
  const routeMatch = useMetricsOrComparisonRouteMatch()

  const setResolutionAtom = useSetRecoilState(resolutionAtom)
  useEffect(() => {
    if (routeMatch?.params.resolution) setResolutionAtom(routeMatch.params.resolution)
  }, [routeMatch?.params.resolution, setResolutionAtom])

  if (!routeMatch) return null
  const { params } = routeMatch

  const resolutions = [Resolutions.daily, Resolutions.weekly, Resolutions.monthly].map(
    (resolution) => ({
      resolution,
      enabled: isResolutionEnabled(resolution, getDaysBetween(params)),
    })
  )

  if (!params.resolution || !isResolutionEnabled(params.resolution, getDaysBetween(params))) {
    return (
      <Redirect
        to={
          "/" +
          [
            params.mode,
            params.location_ids,
            params.start_date,
            params.end_date,
            resolutions.find(({ enabled }) => enabled)?.resolution as Resolution,
          ]
            .filter(Boolean)
            .join("/")
        }
      />
    )
  }

  return (
    <div className="resolution-picker">
      {resolutions.map(({ resolution, enabled }) => (
        <NavLink
          className={`button-toggle clean-button resolution-picker__button ${
            enabled ? "" : "resolution-picker__button-disabled"
          } resolution-picker__button--${resolution}`}
          to={
            "/" +
            [params.mode, params.location_ids, params.start_date, params.end_date, resolution]
              .filter(Boolean)
              .join("/")
          }
          activeClassName="is-toggled"
          key={resolution}
          disabled={!enabled}
          title={translate(`resolution.${resolution}.tooltip`)}
        >
          {translate(`resolution.${resolution}.text`)}
        </NavLink>
      ))}
    </div>
  )
}
