export default {
  portal_name: "Wi-Fi MIS Portal",
  download: {
    as_csv: "Download as CSV",
  },
  navigation_menu: {
    admin: "Admin",
    switch_estates: "Switch Estates",
    comparison_mode: "Comparison",
    metrics_mode: "Metrics",
    agg_metrics_mode: "Agg. Metrics",
    agg_metrics_mode_tooltip: "Aggregate Metrics",
    analyse_mode: "Analytics",
    help: "Help",
    log_out: "Log Out",
    reset_password: "Reset Password",
    locations_link_tooltip: "View Locations",
  },
  locations_list: {
    placeholder: {
      text: "Showing combined data from",
      count: "{count} live sites",
    },
  },
  service_type: {
    engagement: "BT WiFi Engagement",
    insights: "BT WiFi Insights",
    standard: "BT WiFi Standard",
    workforce_wifi: "BT WiFi Workforce Wifi",
    ee_property_wifi: "EE Property WiFi",
  },
  resolution: {
    daily: {
      text: "Daily",
      tooltip: "Available for any 3 month date selection",
    },
    weekly: {
      text: "Weekly",
      tooltip: "Available for any 3 week to 52 week date selection",
    },
    monthly: {
      text: "Monthly",
      tooltip: "Available for 3 months and above date selection",
    },
  },
  date_picker: {
    showing: "Showing data for",
    apply: "Apply",
    range: {
      last_week: "Last week",
      last_four_weeks: "Last 4 weeks",
      last_year: "Last year",
      last_two_years: "Last 2 years",
    },
  },
  search: {
    placeholder: "Enter a site name, site code or location",
    help: { clear: "Clear" },
    your_location: " ",
    aria: {
      back_button: "Cancel search",
      clear: "Clear search",
      intro: "Enter a search term to search for a site or a location",
      results: "There are {count} search results",
    },
  },
  comparison: {
    none_selected: "No locations selected",
  },
  agg_metrics: {
    title: {
      unique_users_site_total_avg_by_day: "Unique users site total avg by day",
      unique_users_estate_total_avg_by_day: "Unique users estate total avg by day",
    },
    keys: {
      unique_users_site_total_avg_by_day: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
      },
      unique_users_estate_total_avg_by_day: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
      },
    },
  },
  metrics: {
    empty: "No data for selected time period",
    total: "Total",
    title: {
      new_and_repeat_users: "New and Repeat Users",
      uploaded_and_downloaded: "Data by Uploaded and Downloaded",
      repeat_users: "Repeat Users",
      megabytes: "Data Used",
      uploaded: "Uploaded",
      downloaded: "Downloaded",
      sessions: "Sessions",
      unique_users_estate_total: "Users - Estate Total",
      users_total: "Users - Estate Total",
      unique_users_site_total: "Users - Site Total",
      users: "Users - Site Total",
      unique_users: "Users",
      minutes: "Minutes Used",
      new_user_registrations: "New Registrations",
      avg_data_per_session: "Data per Session (MB)",
      avg_data_per_user: "Data per User (MB)",
      avg_mins_per_session: "Minutes per Session",
      avg_mins_per_user: "Minutes per User",
      avg_sessions_per_user: "Sessions per User",
    },
    tooltip: {
      unique_users_estate_total:
        "Total unique users across the estate. Available depending on customer journey deployed.",
      users_total:
        "Total unique users across the estate. Available depending on customer journey deployed.",
      unique_users_site_total:
        "Total users by site. Available depending on customer journey deployed.",
      users: "Total users by site. Available depending on customer journey deployed.",
      new_user_registrations:
        "Available only when an automatic login customer journey is deployed.",
      general: "Usage is based on authenticated sessions.",
    },
  },
  userTypes: {
    label: "User Group",
    types: {
      all: "All",
      sponsored: "Sponsored",
      ee_property_wifi: "EE Property WiFi",
      consumer: "Consumer",
      other: "Other",
    },
  },
  loading: { general: "Loading..." },
  analyse: {
    tooltip: {
      general: "Unique users and per user values are based on the unique users total by date.",
    },
    page_control: {
      info: "Page {currentPage} of {totalPages}",
      title_first_page: "First Page",
      title_previous_page: "Previous Page",
      title_neighbour_page: "Page {page}",
      title_next_page: "Next Page",
      title_last_page: "Last Page",
    },
    label_showing: "Showing",
    label_of: "of",
    label_locations_without_metrics_count:
      "As of {date, date, %d%m}, {noOfLocations} live locations are missing metrics ",
    label_locations_without_metrics_link: "download list",
    column_headers: {
      location_id: "Site Code",
      location_name: "Site Name",
    },
    info_line: {
      for: "for",
      sites: "sites",
      for_the_period_from: "for the period from",
      to: "to",
    },
    showing: {
      top_5: "Top 5 %",
      top_10: "Top 10 %",
      top_25: "Top 25 %",
      top_50: "Top 50 %",
      bottom_50: "Bottom 50 %",
      bottom_25: "Bottom 25 %",
      bottom_10: "Bottom 10 %",
      bottom_5: "Bottom 5 %",
      all: "All",
    },
  },
}
