import Moment from "moment"
import {
  CountableTimeInterval,
  timeDay as D3TimeDay,
  timeMonth as D3TimeMonth,
  timeSaturday as D3TimeWeek,
} from "d3-time"
import { Resolutions } from "../values/resolutions"
import { Resolution } from "../recoil/types"

const TIME_TICKS = {
  [Resolutions.daily]: D3TimeDay,
  [Resolutions.weekly]: D3TimeWeek,
  [Resolutions.monthly]: D3TimeMonth,
} as const

export const dateRangeIsSame = function (range1, range2): boolean {
  const startDateIsSame = Moment(range1.startDate).isSame(Moment(range2.startDate), "day")
  const endDateIsSame = Moment(range1.endDate).isSame(Moment(range2.endDate), "day")
  return startDateIsSame && endDateIsSame
}

export const inclusiveRange = function ({
  start,
  end,
  resolution = "daily",
}: {
  start: number
  end: number
  resolution?: Resolution
}): {
  timeInterval: CountableTimeInterval
  start: number
  end: number
} {
  const timeInterval = TIME_TICKS[resolution]
  return {
    timeInterval: timeInterval,
    start: Number(timeInterval.floor(new Date(start + 1))),
    end: timeInterval.ceil(new Date(end + 1)).getTime() - 1,
  }
}

export const getSegmentWidth = function ({ scales, index, resolution }): number {
  const D3TimeInterval = TIME_TICKS[resolution]
  const start = D3TimeInterval.floor(scales.xTicks[index]).getTime()
  const end = D3TimeInterval.ceil(new Date(start + 1e3)).getTime()
  const width = scales.x(end) - scales.x(start)
  return Math.max(1, width)
}
