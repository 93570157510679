import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useRecoilValue } from "@geome/recoil"
import React, { ReactElement } from "react"
import { DownloadIcon } from "../ui/icons/download"
import { useAnalyseRouteMatch } from "../util/hooks/routes"
import { baseUrlAtom } from "../../recoil/atoms"

type getAnalyticsDownloadUrlArgs = {
  baseUrl: string
  metric: string
  showing: string
  start_date: string
  end_date: string
}

const getAnalyticsDownloadUrl = ({
  baseUrl,
  metric,
  showing,
  start_date,
  end_date,
}: getAnalyticsDownloadUrlArgs): string => {
  const { protocol, host } = window.location
  const downloadUrl = new URL(`${protocol}//${host}/${baseUrl}/analytics.csv`)
  downloadUrl.searchParams.append("analytic", metric)
  downloadUrl.searchParams.append("segment", showing)
  downloadUrl.searchParams.append("start_date", start_date)
  downloadUrl.searchParams.append("end_date", end_date)
  return downloadUrl.toString()
}

export const AnalysisDownloadButton = (): ReactElement => {
  const match = useAnalyseRouteMatch()
  const baseUrl = useRecoilValue(baseUrlAtom)
  const { translate } = useI18n()

  if (!match) throw new Error("AnalysisDownloadButton not in analysis")

  return (
    <a
      className="download-button"
      href={getAnalyticsDownloadUrl({ baseUrl, ...match.params })}
      target="_blank"
      title={translate("download.as_csv") as string}
      download
    >
      <DownloadIcon />
    </a>
  )
}
