import React, { Fragment, ReactElement } from "react"
import { AdminPanel } from "../adminPanel"
import { DatePicker } from "../datepicker"
import { ModeSwitcher } from "../modeSwitcher"
import { ResolutionPicker } from "../resolutionPicker"
import { DownloadButton } from "../downloadButton"
import { useDateRangeRouteMatch } from "../util/hooks/routes"
import { useRecoilValue } from "@geome/recoil"
import { userAtom } from "../../recoil/atoms"
import { ListIcon } from "../ui/icons/list"
import { useI18n } from "@geome/react-next"

export const Header = (): ReactElement => {
  const dateRangeMatch = useDateRangeRouteMatch()
  const user = useRecoilValue(userAtom)
  const locationsIndexUrl = user?.locations_index_path
  const { translate } = useI18n()

  return (
    <Fragment>
      <AdminPanel />
      <div className="header__controls">
        <div className="header__date-and-resolution">
          <div>
            {dateRangeMatch?.params.start_date && dateRangeMatch.params.end_date && <DatePicker />}
          </div>
          <ResolutionPicker />
        </div>
        <div className="vertical-divider" />
        <ModeSwitcher />
        <div className="header__side-buttons">
          <DownloadButton />
          <a
            key="locations"
            title={translate("navigation_menu.locations_link_tooltip") as string}
            target="_blank"
            href={locationsIndexUrl}
            rel="noreferrer"
          >
            <ListIcon />
          </a>
        </div>
      </div>
    </Fragment>
  )
}
