import React, { ReactElement } from "react"

type DownloadIconProps = {
  className?: string
  size?: number
}

export const DownloadIcon = ({ className = "", size = 52 }: DownloadIconProps): ReactElement => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="26" cy="26" r="25" fill="white" stroke="#5514B4" strokeWidth="2" />
    <path d="M13.069 35.1454H38.8902" stroke="#5514B4" strokeWidth="4" strokeLinecap="square" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0023 10.4844H23.1165V20.7737H16.39L26.0602 31.0578L35.7303 20.7737H29.0023V10.4844Z"
      fill="#5514B4"
    />
  </svg>
)
