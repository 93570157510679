import { selector, selectorFamily } from "@geome/recoil"
import { LatLngBounds } from "@geome/types"
import moment from "moment"
import { inclusiveRange } from "../util/dates"
import locationTypes from "../values/locationTypes"
import { AggregatedMetricsSelectorFamily } from "./aggregated/selectors"
import {
  baseConfigAtom,
  baseUrlAtom,
  endDateAtom,
  estateAtom,
  resolutionAtom,
  selectedLocationIdsAtom,
  startDateAtom,
  userTypeAtom,
} from "./atoms"
import {
  AnalyticsMeta,
  AnalyticsResponseRow,
  BTConfig,
  BTLocation,
  MetricDataPoint,
  MetricName,
  MetricsResponse,
  Resolution,
} from "./types"

export const LocationSelectorFamily = selectorFamily<BTLocation, BTLocation["id"]>({
  key: "LocationSelectorFamily",
  get:
    (id) =>
    async ({ get }) => {
      const baseUrl = get(baseUrlAtom)
      const estate = get(estateAtom)
      if (id === "estate") {
        return {
          id: "estate",
          name: estate?.name || "Estate",
          status: "Live",
          lat: 1,
          lng: 1,
        }
      }
      const location = (await (await fetch(`${baseUrl}/locations/${id}`)).json()) as BTLocation
      return location as BTLocation
    },
})

export const selectedLocationsSelector = selector<BTLocation[]>({
  key: "selectedLocationsSelector",
  get: async ({ get }) => {
    const ids = get(selectedLocationIdsAtom)
    return await Promise.all(ids.map((id) => get(LocationSelectorFamily(id))))
  },
})

const BOUNDS_PADDING = 0.01
export const selectedLocationsTargetBoundsSelector = selector<LatLngBounds | null>({
  key: "selectedLocationsTargetBoundsSelector",
  get: ({ get }) => {
    const selectedLocations = get(selectedLocationsSelector)
    if (selectedLocations.length === 0) return null

    const firstLocation = selectedLocations[0]

    return {
      north: firstLocation.lat - BOUNDS_PADDING,
      south: firstLocation.lat + BOUNDS_PADDING,
      east: firstLocation.lng + BOUNDS_PADDING,
      west: firstLocation.lng - BOUNDS_PADDING,
    }
  },
})

export const AnalysePageSelectorFamily = selectorFamily<
  {
    meta: AnalyticsMeta
    rows: AnalyticsResponseRow[]
  },
  {
    metric: string
    start_date: string
    end_date: string
    showing: string
    page: string
  }
>({
  key: "AnalysePageSelectorFamily",
  get:
    ({ metric, start_date, end_date, showing, page }) =>
    async ({ get }) => {
      const baseUrl = get(baseUrlAtom)
      const repsonse = await fetch(
        `${baseUrl}/analytics?${new URLSearchParams({
          start_date,
          end_date,
          analytic: metric,
          segment: showing,
          format: "json",
          per_page: "20",
          page: `${page}`,
        })}`
      )

      const meta = {
        page: parseInt(repsonse.headers.get("page") || "-1"),
        perPage: parseInt(repsonse.headers.get("per-page") || "-1"),
        total: parseInt(repsonse.headers.get("total") || "-1"),
      }

      const rows = (await repsonse.json()) as AnalyticsResponseRow[]

      const startingIndex = Math.max(0, (meta.page - 1) * meta.perPage)
      const bumpedRows = rows.map(({ row_index, ...rest }) => ({
        row_index: startingIndex + row_index,
        ...rest,
      }))

      return {
        meta,
        rows: bumpedRows,
      }
    },
})

export const MetricsSelectorFamily = selectorFamily<
  MetricsResponse,
  {
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
  }
>({
  key: "MetricsSelectorFamily",
  get:
    ({ location_id, start_date, end_date, resolution }) =>
    async ({ get }) => {
      const baseUrl = get(baseUrlAtom)
      const userType = get(userTypeAtom)

      const url = `${baseUrl}/${
        location_id !== locationTypes.ESTATE ? `locations/${location_id}/` : ""
      }metrics`

      const { start, end } = inclusiveRange({
        start: moment(start_date).valueOf(),
        end: moment(end_date).valueOf(),
        resolution,
      })

      const response = await fetch(
        `${url}?${new URLSearchParams({
          start_date: moment(start).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
          resolution,
          usage_group_type: userType !== "all" ? userType : "",
          format: "json",
        })}`
      )

      return (await response.json()) as MetricsResponse
    },
})

export const SingleMetricSelectorFamily = selectorFamily<
  MetricDataPoint[],
  {
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
    metric: MetricName
  }
>({
  key: "SingleMetricSelectorFamily",
  get:
    ({ location_id, start_date, end_date, resolution, metric }) =>
    async ({ get }) => {
      const metricsResponse = get(
        MetricsSelectorFamily({ location_id, start_date, end_date, resolution })
      )
      return metricsResponse.data[metric]
    },
})

export const ComparisonMetricSelectorFamily = selectorFamily<
  MetricDataPoint[][],
  {
    location_ids?: string[]
    start_date: string
    end_date: string
    resolution: Resolution
    metric: MetricName
  }
>({
  key: "ComparisonMetricSelectorFamily",
  get:
    ({ location_ids, start_date, end_date, resolution, metric }) =>
    async ({ get }) => {
      if (!location_ids) return []
      const metricsResponse = location_ids.map(
        (location_id) =>
          get(MetricsSelectorFamily({ location_id, start_date, end_date, resolution })).data[metric]
      )
      return metricsResponse
    },
})

export const MetricsHasDataSelectorFamily = selectorFamily<
  boolean,
  { location_id: string; start_date: string; end_date: string; resolution: Resolution }
>({
  key: "MetricsHasDataSelectorFamily",
  get:
    ({ location_id, start_date, end_date, resolution }) =>
    async ({ get }) => {
      const metricsResponse = get(
        MetricsSelectorFamily({ location_id, start_date, end_date, resolution })
      )
      return Object.entries(metricsResponse.data).some(([_key, value]) => value.length > 0)
    },
})

export const ComparisonHasDataSelectorFamily = selectorFamily<
  boolean,
  { location_ids?: string[]; start_date: string; end_date: string; resolution: Resolution }
>({
  key: "ComparisonHasDataSelectorFamily",
  get:
    ({ location_ids, start_date, end_date, resolution }) =>
    async ({ get }) => {
      if (!location_ids) return false
      const metricsWithData = location_ids.map((location_id) =>
        get(MetricsHasDataSelectorFamily({ location_id, start_date, end_date, resolution }))
      )
      return metricsWithData.some(Boolean)
    },
})

export const downloadConfigSelector = selector<BTConfig["download"]>({
  key: "downloadConfigSelector",
  get: ({ get }) => {
    const allConfig = get(baseConfigAtom)
    if (!allConfig)
      return { columns_single_location: [], columns_estate: [], columns_comparison: [] }
    return allConfig.download
  },
})

export const aggMetricsDownloadConfigSelector = selector<BTConfig["aggregated_metrics_download"]>({
  key: "aggMetricsDownloadConfigSelector",
  get: ({ get }) => {
    const allConfig = get(baseConfigAtom)
    if (!allConfig) return {}
    return allConfig.aggregated_metrics_download
  },
})

export const allDateParamsSelector = selector<{
  end_date: string
  start_date: string
  resolution: Resolution
} | null>({
  key: "allDateParamsSelector",
  get: ({ get }) => {
    const start_date = get(startDateAtom)
    const end_date = get(endDateAtom)
    const resolution = get(resolutionAtom)

    if (start_date === null || end_date === null) return null
    return { start_date, end_date, resolution: resolution || "daily" }
  },
})

export const overridenConfigSelector = selector<BTConfig | null>({
  key: "overridenConfigSelector",
  get: ({ get }) => {
    const baseConfig = get(baseConfigAtom)
    const dateParams = get(allDateParamsSelector)
    if (!baseConfig || !dateParams) return baseConfig

    // TODO: could change this to switch between metrics & agg_metrics based on what's shown
    let configs: (Partial<BTConfig> | undefined)[] = []

    const selectedLocationIds = get(selectedLocationIdsAtom)
    const ids = selectedLocationIds.length > 0 ? selectedLocationIds : ["estate"]
    const metrics = ids.map((location_id) =>
      get(MetricsSelectorFamily({ location_id, ...dateParams }))
    )

    configs = [...configs, ...metrics.map(({ config }) => config)]

    if (ids.length === 1) {
      const aggMetrics = get(
        AggregatedMetricsSelectorFamily({ location_id: ids[0], ...dateParams })
      )
      configs = [...configs, aggMetrics.config]
    }

    const mergedConfig = configs.reduce((acc: BTConfig, current): BTConfig => {
      if (!current) return acc
      const newObj: BTConfig = { ...acc }
      for (const key in current) {
        if (current[key]) newObj[key] = current[key]
      }
      return newObj
    }, baseConfig)

    return mergedConfig as BTConfig
  },
})
