import React, { Fragment, ReactElement, Suspense } from "react"
import { ConfigContextProvider } from "@geome/react-next/lib/context/config"
import { AppLayout } from "./ui/appLayout"
import { Header } from "./header"
import { Resources } from "./resources"
import Moment from "moment"
import {
  metricsPath,
  comparisonPath,
  analysePath,
  dateRangePath,
  resolutionPath,
  aggMetricsPath,
} from "../values/pathParts"
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { Loading } from "./loading"
import { RecoilMultiInitRoot } from "@geome/recoil"
import { initialiseResourcesState } from "@geome/recoil/dist/feature/resources/initialisers"
import { GeospatialApiRequesterContextProvider } from "@geome/geospatial-api-requester"
import config from "../config"
import {
  initialiseBaseConfig,
  initialiseBaseURL,
  initialiseEstateAndUser,
} from "../recoil/initialisers"
import { BTConfig } from "../recoil/types"
import { GoogleMapsLoaderUsingConfig } from "@geome/react-components-next/lib/components/map/google/loader"
import { I18nContextProvider } from "@geome/react-next/lib/context/i18nContext/provider"

const Metrics = React.lazy(async () => ({ default: (await import("./metrics")).Metrics }))
const AggMetrics = React.lazy(async () => ({ default: (await import("./aggMetrics")).AggMetrics }))
const Analyse = React.lazy(async () => ({ default: (await import("./analyse")).Analyse }))
const Comparison = React.lazy(async () => ({ default: (await import("./comparison")).Comparison }))
const Sidebar = React.lazy(async () => ({ default: (await import("./sidebar")).Sidebar }))

class App extends React.Component {
  defaultEndDate(): string {
    return Moment().format("YYYY-MM-DD")
  }

  defaultStartDate(): string {
    const today = new Date()
    const fourWeeksAgo = new Date()
    fourWeeksAgo.setDate(today.getDate() - 7 * 4)
    return Moment(fourWeeksAgo).format("YYYY-MM-DD")
  }

  redirectToEstate(props): ReactElement | null {
    return (
      <Redirect
        to={{
          pathname: `/metrics/estate/${this.defaultStartDate()}/${this.defaultEndDate()}`,
          state: { from: props.location },
        }}
      />
    )
  }

  renderContentRouting(): ReactElement | null {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={(props) => this.redirectToEstate(props)} />
          <Route
            path={`/${metricsPath}/${dateRangePath}/${resolutionPath}`}
            component={() => <Metrics />}
          />
          <Route exact path="/metrics" component={(props) => this.redirectToEstate(props)} />
          <Route path={`/${aggMetricsPath}/${dateRangePath}`} component={() => <AggMetrics />} />
          <Route
            path={`/${comparisonPath}/${dateRangePath}/${resolutionPath}`}
            component={Comparison}
          />
          <Route path={`/${analysePath}/${dateRangePath}/`} component={Analyse} />
        </Switch>
      </Suspense>
    )
  }

  render(): ReactElement {
    return (
      <ConfigContextProvider value={config}>
        {(finalConfig: BTConfig) => (
          <GeospatialApiRequesterContextProvider>
            <RecoilMultiInitRoot
              initialisers={[
                initialiseResourcesState(finalConfig),
                initialiseBaseURL(finalConfig),
                initialiseEstateAndUser(finalConfig),
                initialiseBaseConfig(finalConfig),
              ]}
            >
              <I18nContextProvider
                intlData={finalConfig.intlData}
                locale={finalConfig.locale}
                intlExplorer={finalConfig.intlExplorer}
                mode={process.env.NODE_ENV !== "prod" ? "development" : "production"}
              >
                <GoogleMapsLoaderUsingConfig>
                  <Router>
                    <div>
                      <AppLayout
                        renderHeader={() => <Header />}
                        renderContent={() => (
                          <Fragment>
                            {this.renderContentRouting()}
                            <Resources />
                          </Fragment>
                        )}
                        renderSidebar={() => (
                          <Suspense fallback={<Loading />}>
                            <Sidebar />
                          </Suspense>
                        )}
                      />
                    </div>
                  </Router>
                </GoogleMapsLoaderUsingConfig>
              </I18nContextProvider>
            </RecoilMultiInitRoot>
          </GeospatialApiRequesterContextProvider>
        )}
      </ConfigContextProvider>
    )
  }
}

export default App
