import { AppConfig } from "@geome/types"
import { MutableSnapshot } from "recoil"
import { baseConfigAtom, baseUrlAtom, estateAtom, userAtom } from "./atoms"
import { BTConfig } from "./types"

export const initialiseBaseURL =
  (config: Pick<AppConfig, "baseUrl">) =>
  ({ set }: MutableSnapshot) => {
    set(baseUrlAtom, config.baseUrl)
  }

export const initialiseEstateAndUser =
  (config: BTConfig) =>
  ({ set }: MutableSnapshot) => {
    set(estateAtom, config.estate ?? config.estate_group)
    set(userAtom, config.user)
  }

export const initialiseBaseConfig =
  (config: BTConfig) =>
  ({ set }: MutableSnapshot) => {
    set(baseConfigAtom, config)
  }
