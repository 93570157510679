import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react"

type Position = {
  top: number
  left: number
  right: number
  bottom: number
  width: number
  height: number
}

type GetPositionProps = {
  children: (position: Position) => ReactNode
}

export const GetPosition = ({ children }: GetPositionProps): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (!wrapperRef.current) return
    const box = wrapperRef.current.getBoundingClientRect()
    setPosition({
      top: box.top,
      left: box.left,
      right: box.right,
      bottom: box.bottom,
      width: box.width,
      height: box.height,
    })
  }, [])

  return <div ref={wrapperRef}>{children(position)}</div>
}
