import { useReducer } from "react"

const init = (params) => ({
  applied: false,
  focusedInput: null,
  dates: {
    start_date: params?.start_date,
    end_date: params?.end_date,
  },
})

const reducer = (state, action) => {
  switch (action.type) {
    case "change_dates":
      return {
        ...state,
        dates: {
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
        applied: false,
      }
    case "focus_input":
      return {
        ...state,
        focusedInput: action.payload,
      }
    case "apply":
      return {
        ...state,
        focusedInput: null,
        applied: true,
      }
    case "has_redirected":
      return {
        ...state,
        applied: false,
      }
    default:
      throw new Error()
  }
}

const useDatePickerReducer = (matchParams) => useReducer(reducer, matchParams, init)

export { useDatePickerReducer }
