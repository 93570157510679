import React, { ReactElement, useMemo } from "react"
import { NavLink, useRouteMatch } from "react-router-dom"
import {
  metricsPath,
  dateRangePath,
  resolutionPath,
  comparisonPath,
  analysePath,
  aggMetricsPath,
} from "../../values/pathParts"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { RequiredRouteParams } from "../util/hooks/routes"
import { Resolution } from "../../recoil/types"

const linksForMetricsRoutes = (
  routeMatch: RequiredRouteParams<{
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
  }>
): [string, string, string, string] => {
  const { location_id, start_date, end_date, resolution } = routeMatch.params
  return [
    routeMatch.url,
    ["/agg_metrics", location_id, start_date, end_date].join("/"),
    ["/comparison", location_id !== "estate" ? location_id : null, start_date, end_date, resolution]
      .filter(Boolean)
      .join("/"),
    ["/analyse", start_date, end_date].join("/"),
  ]
}

const linksForAggMetricsRoutes = (
  routeMatch: RequiredRouteParams<{
    location_id: string
    start_date: string
    end_date: string
    resolution: Resolution
  }>
): [string, string, string, string] => {
  const { location_id, start_date, end_date, resolution } = routeMatch.params
  return [
    [
      "/metrics",
      location_id !== "estate" ? location_id : "estate",
      start_date,
      end_date,
      resolution,
    ]
      .filter(Boolean)
      .join("/"),
    routeMatch.url,
    ["/comparison", location_id !== "estate" ? location_id : null, start_date, end_date, resolution]
      .filter(Boolean)
      .join("/"),
    ["/analyse", start_date, end_date].join("/"),
  ]
}

const linksForComparisonRoutes = (
  routeMatch: RequiredRouteParams<{
    location_ids: string
    start_date: string
    end_date: string
    resolution: Resolution
  }>
): [string, string, string, string] => {
  const { location_ids, start_date, end_date, resolution } = routeMatch.params
  return [
    [
      "/metrics",
      (location_ids && location_ids.split("/")[0]) || "estate",
      start_date,
      end_date,
      resolution,
    ]
      .filter(Boolean)
      .join("/"),
    ["/agg_metrics", (location_ids && location_ids.split("/")[0]) || "estate", start_date, end_date]
      .filter(Boolean)
      .join("/"),
    routeMatch.url,
    ["/analyse", start_date, end_date].join("/"),
  ]
}

const linksForAnalyseRoutes = (
  routeMatch: RequiredRouteParams<{
    start_date: string
    end_date: string
  }>
): [string, string, string, string] => {
  const { start_date, end_date } = routeMatch.params
  return [
    ["/metrics", "estate", start_date, end_date].join("/"),
    ["/agg_metrics", "estate", start_date, end_date].join("/"),
    ["/comparison", start_date, end_date].join("/"),
    routeMatch.url,
  ]
}

export const ModeSwitcher = (): ReactElement => {
  const { translate } = useI18n()

  const metricsRouteMatch = useRouteMatch(`/${metricsPath}/${dateRangePath}/${resolutionPath}`)
  const aggMetricsRouteMatch = useRouteMatch(`/${aggMetricsPath}/${dateRangePath}`)
  const comparisonRouteMatch = useRouteMatch(
    `/${comparisonPath}/${dateRangePath}/${resolutionPath}`
  )
  const analyseRouteMatch = useRouteMatch(
    `/${analysePath}/${dateRangePath}/showing/:showing?/of/:metric?/page/:page?`
  )

  const [metricsLink, aggMetricsLink, comparisonLink, analyseLink] = useMemo(() => {
    if (metricsRouteMatch) {
      return linksForMetricsRoutes(metricsRouteMatch)
    } else if (aggMetricsRouteMatch) {
      return linksForAggMetricsRoutes(aggMetricsRouteMatch)
    } else if (comparisonRouteMatch) {
      return linksForComparisonRoutes(comparisonRouteMatch)
    } else if (analyseRouteMatch) {
      return linksForAnalyseRoutes(analyseRouteMatch)
    } else {
      return ["", "", "", ""]
    }
  }, [metricsRouteMatch, aggMetricsRouteMatch, comparisonRouteMatch, analyseRouteMatch])

  return (
    <div className="mode-switcher">
      <NavLink
        key="metrics"
        to={metricsLink}
        className="button-toggle button-toggle--secondary mode-switcher__button--metrics mode-switcher__button clean-button"
        activeClassName="is-toggled"
      >
        {translate("navigation_menu.metrics_mode")}
      </NavLink>
      <NavLink
        key="Agg. Metrics"
        to={`${aggMetricsLink}/`}
        className="button-toggle button-toggle--secondary mode-switcher__button--agg-metrics mode-switcher__button clean-button"
        activeClassName="is-toggled"
        title={translate("navigation_menu.agg_metrics_mode_tooltip")}
      >
        {translate("navigation_menu.agg_metrics_mode")}
      </NavLink>
      <NavLink
        key="analyse"
        to={`${analyseLink}/`}
        className="button-toggle button-toggle--secondary mode-switcher__button--analyse mode-switcher__button clean-button"
        activeClassName="is-toggled"
      >
        {translate("navigation_menu.analyse_mode")}
      </NavLink>
      <NavLink
        key="comparison"
        to={comparisonLink}
        className="button-toggle button-toggle--secondary mode-switcher__button--comparison mode-switcher__button clean-button"
        activeClassName="is-toggled"
      >
        {translate("navigation_menu.comparison_mode")}
      </NavLink>
    </div>
  )
}
