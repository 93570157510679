import React, { ReactElement } from "react"
import serviceTypes from "../../values/serviceTypes"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"

const ICONS = {
  [serviceTypes.ENGAGEMENT]: {
    src: require("../../../images/service_types/bt-wifi-engagement.svg"),
    i18nKey: "service_type.engagement",
  },
  [serviceTypes.INSIGHTS]: {
    src: require("../../../images/service_types/bt-wifi-insights.svg"),
    i18nKey: "service_type.insights",
  },
  [serviceTypes.STANDARD]: {
    src: require("../../../images/service_types/bt-wifi-standard.svg"),
    i18nKey: "service_type.standard",
  },
  [serviceTypes.WORKFORCE_WIFI]: {
    src: require("../../../images/service_types/bt-wifi-workforce-wifi.svg"),
    i18nKey: "service_type.workforce_wifi",
  },
  [serviceTypes.EE_PROPERTY_WIFI]: {
    src: require("../../../images/service_types/ee-personal-wifi.svg"),
    i18nKey: "service_type.ee_property_wifi",
  },
} as const

export type ServiceTypeIconProps = {
  className?: string
  serviceType:
    | "BT WIFI ENGAGEMENT"
    | "BT WIFI INSIGHTS"
    | "BT WIFI STANDARD"
    | "BT WIFI WORKFORCE WIFI"
    | "EE PROPERTY WIFI"
}

export const ServiceTypeIcon = ({
  serviceType,
  className,
}: ServiceTypeIconProps): ReactElement | null => {
  const { translate } = useI18n()
  const icon = ICONS[serviceType]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!icon) return null
  return <img className={className} src={icon.src} title={translate(icon.i18nKey) as string} />
}
