import React from "react"
import { createRoot } from "react-dom/client"
import { initLog } from "@geome/fe-log"

// padStart needed for react-dates in IE11
import "core-js/features/string/pad-start"
import "react-dates/initialize"
import App from "./components/app"

const deployEnv = process.env.DEPLOY_ENV

initLog({
  logglyKey: "89f85593-200b-45dd-a38f-0a12d24e0a62",
  appName: "btwifidashboardfe",
  deployEnv,
})

const render = (): void => {
  const container = document.getElementById("app")
  if (!container) throw Error("`.app` element missing")
  const root = createRoot(container)
  root.render(<App />)
}

document.addEventListener("DOMContentLoaded", () => render())
